export const App = () => {
	return (
		<>
			<header className="header">this is header</header>
			<div>
				<main className="main">
					<h1>Hello</h1>
					<h3>First deploy</h3>
				</main>
			</div>
			<footer className="footer">this is footer</footer>
		</>
	);
};
